import posthog, { CaptureResult } from 'posthog-js';
import { getEnv } from './utils';

function devLogEvent(event: CaptureResult | null) {
  const env = getEnv();
  if (env === 'development' || env === 'emulator' || env === 'staging') {
    console.log(`PostHog ${env} event:`, event);
    return null; // Event == null means don't send the event to PostHog
  }
  return event;
}

// Paths that we want to control event collection for
const WHEEL_OF_NAMES_PATHS = ['/wheel-of-names', '/ewon'];

// Helper function to check if current path matches controlled paths
function isWheelOfNamesPath(path: string): boolean {
  const normalizedPath = path.toLowerCase();
  const isWonPath = WHEEL_OF_NAMES_PATHS.some((wonPath) =>
    normalizedPath.startsWith(wonPath)
  );
  return isWonPath;
}

function controlWheelOfNamesAnalytics(event: CaptureResult | null) {
  const currentPath = window.location.pathname + window.location.search;

  if (isWheelOfNamesPath(currentPath)) {
    const shouldCollectPageViewEvents = true;
    const shouldCollectOtherEvents = false;
    const isPageViewEvent = event?.event === '$pageview';

    if (
      (isPageViewEvent && !shouldCollectPageViewEvents) ||
      (!isPageViewEvent && !shouldCollectOtherEvents)
    ) {
      return null; // Event == null means don't send the event to PostHog
    }
  }
  return event;
}

const posthogEnabled = true;
const posthogApiKey = process.env.GATSBY_POSTHOG_KEY || '';
const posthogHost = process.env.GATSBY_POSTHOG_HOST;

class PostHogAnalytics {
  private static instance: PostHogAnalytics;
  private initialized: boolean = false;

  private constructor() {
    if (typeof window !== 'undefined' && posthogEnabled) {
      posthog.init(posthogApiKey, {
        api_host: posthogHost,
        persistence: 'memory',
        disable_persistence: true,
        capture_pageview: false, // We'll handle this manually
        before_send: [controlWheelOfNamesAnalytics, devLogEvent],
      });
      this.initialized = true;
    }
  }

  public static getInstance(): PostHogAnalytics {
    if (!PostHogAnalytics.instance) {
      PostHogAnalytics.instance = new PostHogAnalytics();
    }
    return PostHogAnalytics.instance;
  }

  public pageView(url: string, properties?: Record<string, any>): void {
    if (!this.initialized) return;

    posthog.capture('$pageview', {
      url,
      ...properties,
    });
  }

  public track(eventName: string, properties?: Record<string, any>): void {
    if (!this.initialized) return;

    posthog.capture(eventName, properties);
  }

  public identify(distinctId: string, properties?: Record<string, any>): void {
    if (!this.initialized) return;

    posthog.identify(distinctId, properties);
  }

  public reset(): void {
    if (!this.initialized) return;

    posthog.reset();
  }
}

export const posthogAnalytics = PostHogAnalytics.getInstance();
